@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply text-accent;
  }
}

body {
  font-family: "Mulish";
  display: flex;
  justify-content: center;
}

.screen {
  max-width: 1440px;
}

/* VANTI (vt) general components */
@layer components {
  /* VANTI GRID */
  .vt-grid {
    @apply grid xs:px-4 lg:p-0 xs:grid-cols-4 lg:grid-cols-8 xl:grid-cols-12 gap-4;
  }

  .single-card {
    @apply bg-white xs:p-1 lg:p-4 w-full border border-neutral-120 rounded-lg;
  }

  .blue-card {
    @apply bg-neutral-100 xs:p-2 lg:p-4 w-full lg:rounded-lg;
  }

  /* 
  * [Form group element]
  */
  .form-group {
    @apply flex flex-col gap-2 justify-start h-full relative;

    .form-error {
      @apply text-xs text-red-500 font-normal;
    }

    .sub-text {
      @apply text-xs text-neutral-50 font-normal;
    }

    .form-icon {
      @apply absolute w-6 h-6 bg-white top-2 right-3 cursor-pointer;

      &.label {
        @apply top-10;
      }
    }
  }

  /*
  * [Input Text]
  */
  .input-field {
    @apply border border-neutral-120 px-3 py-2 rounded outline-none read-only:bg-neutral-40 max-h-10;
  }

  /*
  * [Select Input]
  */
  .select-field {
    @apply border border-neutral-120 px-3 py-2 rounded outline-none bg-white;
  }

  /*
  * [Custom button styles]
  */
  .btn {
    @apply py-2 px-3 rounded-3xl border-0;

    &.btn-primary {
      @apply bg-primary disabled:text-gray-60 hover:bg-primary-hover active:bg-primary-hover disabled:bg-primary-disabled;
    }

    &.btn-secondary {
      @apply bg-accent text-white disabled:text-neutral-70 hover:bg-accent-hover active:bg-accent-hover disabled:bg-accent-disabled;
    }

    &.btn-outline {
      @apply bg-white text-accent border border-accent hover:bg-accent hover:text-white active:bg-accent disabled:bg-gray-90 disabled:border-gray-60 disabled:text-gray-60;
    }

    &.btn-text-button {
      @apply bg-white text-accent underline hover:text-accent-hover active:text-accent-hover disabled:text-gray-60;
    }

    &.btn-soft {
      @apply bg-neutral-90 border border-neutral-120 disabled:text-neutral-70 hover:bg-neutral-70 active:bg-neutral-70 disabled:bg-accent-disabled;
    }
  }

  .sm-table {
    thead {
      @apply bg-neutral-40;

      tr {
        @apply border-b border-neutral-120;

        th {
          @apply p-4 font-bold text-start;
        }
      }
    }

    tbody {
      tr {
        @apply border-b border-neutral-120 last:border-b-0;

        td {
          @apply px-4 py-3;
        }
      }
    }

    tfoot {
      @apply bg-[#F3F3F3];

      tr {
        @apply border-b border-neutral-120 last:border-b-0;

        td {
          @apply p-4 font-bold text-start;
        }
      }
    }
  }
}

.bg-blur {
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.3);

  .mat-mdc-dialog-container {
    &.mdc-dialog__surface {
      border-radius: 20px;
    }
  }
}

input[type="radio"],
input[type="checkbox"] {
  accent-color: #113455;
  transform: scale(1.35);
}

.dialog-round {
  &.cdk-overlay-pane {
    &.mat-mdc-dialog-panel {
      @media screen and (min-width: 360px) {
        max-width: 100vw;
      }

      @media screen and (min-width: 1024px) {
        max-width: 80vw;
      }
    }
    mat-dialog-container {
      .mat-mdc-dialog-surface.mdc-dialog__surface {
        @apply rounded-xl;
      }
    }
  }
}

.mat-calendar {
  border-radius: 8px;
  height: 100%;
  width: 300px;
  background-color: #FFFFFF;

  @media screen and (min-width: 420px) {
    width: 350px;
  }

  .mat-calendar-header {
    .mat-calendar-controls {
      margin-top: 0px;

      &>label {
        display: none;
      }

      .mat-calendar-period-button {
        order: 2;
        width: 100%;
        font-weight: bold;

        .mdc-button__label {
          svg {
            display: none;
          }
        }
      }

      .mat-calendar-previous-button {
        order: 1;
        background: #edf5ff;
        width: 38px;
        height: 38px;
        padding: 20px;
      }

      .mat-calendar-next-button {
        order: 3;
        background: #edf5ff;
        width: 38px;
        height: 38px;
        padding: 20px;
      }      
    }
  }

  .mat-calendar-content {
    .mat-calendar-table {
      .mat-calendar-table-header {
        .cdk-visually-hidden {
          display: none;
        }

        span[aria-hidden="true"] {
          font-size: 12px;
          font-weight: bold;
        }
      }

      .mat-calendar-body {
        .mat-calendar-body-label {
          visibility: hidden;
          position: relative;
          font-size: 0px;
          padding-top: 0px !important;
          padding-bottom: 0px !important;
        }

        .range-date {
          background: #edf5ff;
          border-radius: 50%;
        }

        .range-date {
          background: #edf5ff;
          border-radius: 50%;
        }
        
        .mat-calendar-body-cell-container {
          .mat-calendar-body-cell {
            width: 90%;
            height: 90%;

            .mat-calendar-body-cell-content {
              border-width: 0px;

              &::before {
                margin: 0;
              }

              &:hover {
                border-width: 0px;
                background-color: #ebedf0;
                color: rgb(17 52 85 / var(--tw-text-opacity));
              }

            }
            
            &.mat-calendar-body-disabled {
              opacity: 0.3;
              
              &:hover {
                border-width: 0px;
                background-color: #FFFFFF;
              }
            }
          }

          .mat-calendar-body-selected {
            border-width: 1px;
            background-color: rgb(17 52 85 / var(--tw-text-opacity));
            color: white;
          }
        }
      }
    }
  }
  
  .occupied-dates {
    background-color: #edf5ff;
    border-radius: 50%;

    &.mat-calendar-body-active {
      border-width: 1px;
      background-color: rgb(17 52 85 / var(--tw-text-opacity));
      color: white;
    }
  }
  
  .today-date::after {
    content: "•";
    position: absolute;
    top: 80%;
    left: 40%;
    z-index: 0;
    box-sizing: border-box;
    display: block;
    height: 10%;
    width: 10%;
    font-size: 15px;
  }
}  

.mat-datepicker-content-container {
  .mat-datepicker-close-button {
    display: none;
  }
}

.mat-mdc-select {
  color: #98A1B0;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(194 199 208 / var(--tw-border-opacity));
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
  background-color: #FFFFFF;

  .mat-mdc-select-arrow {
    width: 15px;
    height: 15px;

    svg {
      display: none;
    }

    &:before {
      content: "   ";
      background: url("assets/svgs/chevron-down.svg") no-repeat;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}

.sm-select {
  
  &.mat-mdc-select-panel {
    width: 99%;
    background-color: #FFFFFF;
    margin-left: -0.75rem;
    margin-top: 1rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(194 199 208 / var(--tw-border-opacity));
    border-radius: 0.25rem;
    padding: 0;

    mat-option {
      &:hover {
        --tw-bg-opacity: 1;
        background-color: rgb(238 246 253 / var(--tw-bg-opacity));
      }

      &.mat-mdc-option-active.mdc-list-item--selected {
        --tw-bg-opacity: 1;
        background-color: rgb(202 216 232 / var(--tw-bg-opacity));
      }
    }
  }

  &.multiple {
    &.mat-mdc-select-panel {  
      mat-option {
        .mat-pseudo-checkbox-checked {
          background-color: #113455;
          color: #FFFFFF;
          border-color: #113455;
        }
      }
    }
  }
}